<template>
  <div>
    <v-row>
      <v-btn
        v-for="(btn, index) in notificationBtns"
        :key="index"
        class="mt-4"
        @click="handleClick(btn, index)"
      >
        {{ btn.label }}
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mx-auto" max-width="344">
          <v-card-text>
            <p class="text-h4 text--primary">User</p>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Preferred Username</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.preferred_username
                    ? getUser.preferred_username
                    : 'Null'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.email ? getUser.email : 'Null'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Phone Number</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.phone_number ? getUser.phone_number : 'Null'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Role</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.role ? getUser.role : 'Null'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Sub</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.sub ? getUser.sub : 'Null'
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
        <v-container>
          <v-btn @click="displayUpload" icon>
            <v-icon size="30">mdi-plus</v-icon>
          </v-btn>
        </v-container>
        <ImageUpload />
        <ImageCropper />
        <div>
          <v-img :src="croppedImage" alt="test" width="280" height="280" />
        </div>
      </v-col>
    </v-row>
    <Tiptap />
  </div>
</template>
<script>
import Tiptap from '../components/shared/customComponents/richTextEditor/Tiptap.vue';
import ImageUpload from '@/components/shared/imageManipulation/image-upload.vue';
import ImageCropper from '@/components/shared/imageManipulation/image-cropper.vue';

export default {
  name: 'Sandbox',
  components: { ImageUpload, ImageCropper, Tiptap },

  data() {
    return {
      notificationBtns: [
        { label: 'TOP LEFT', value: 'top_left' },
        { label: 'TOP RIGHT', value: 'top_right' },
        { label: 'BOTTOM LEFT', value: 'bottom_left' },
        { label: 'BOTTOM RIGHT', value: 'bottom_right' },
        { label: 'DEFAULT', value: 'default' },
      ],
    };
  },

  methods: {
    handleClick(btn, idx) {
      let toastParams = { position: {} };
      switch (btn.value) {
        case 'top_left':
          toastParams.message = `I AM ${btn.label} TOAST`;
          toastParams.timeout = 3000 + +idx;
          toastParams.position.top = true;
          toastParams.position.left = true;
          toastParams.type = 'error';
          toastParams.btnActionLabel = 'DONE';
          toastParams.btnActionShow = true;
          toastParams.actionBtnPress = this.functionBtnPress;
          toastParams.actionAfterClose = this.functionTest;
          break;
        case 'top_right':
          toastParams.timeout = 3000 + +idx;
          toastParams.message = `I AM ${btn.label} TOAST`;
          toastParams.position.top = true;
          toastParams.position.right = true;
          toastParams.type = 'info';
          break;
        case 'bottom_left':
          toastParams.timeout = 3000 + +idx;
          toastParams.message = `I AM ${btn.label} TOAST`;
          toastParams.position.bottom = true;
          toastParams.position.left = true;
          toastParams.type = 'warning';
          break;
        case 'bottom_right':
          toastParams.message = `I AM ${btn.label} TOAST`;
          toastParams.position.bottom = true;
          toastParams.position.right = true;
          toastParams.type = 'success';
          toastParams.btnActionLabel = 'CANCEL';
          toastParams.btnActionShow = true;
          toastParams.timeout = 3000;
          break;
        case 'default':
          break;
      }
      this.$root.message.showToastNotification(toastParams);
    },

    functionBtnPress() {
      const fakeObj = {
        type: 'buttonPressed',
        value: [1, 2, 3, 4, 5],
      };
      console.log('Button pressed => ', fakeObj.value);
    },

    functionTest() {
      const fakeObj = {
        type: 'afterClose',
        value: [6, 7, 8, 9, 10],
      };
      console.log('After close => ', fakeObj.value);
    },

    displayUpload() {
      this.$store.dispatch('imageManipulation/displayUpload', true);
    },
  },

  computed: {
    getUser() {
      return this.$store.state.user.userInfo;
    },

    croppedImage() {
      return this.$store.state.imageManipulation.croppedImage;
    },
  },
};
</script>
