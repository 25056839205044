<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="imageManipulationStore.displayUpload"
    max-width="550px"
  >
    <v-card
      @drop.prevent="onFileDropped($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey lighten-2': dragover }"
      class="pa-2"
    >
      <v-card-text>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-icon
            :class="[dragover ? 'mt-2, mb-6,' : 'mt-5']"
            :color="uploadedFiles.length > 0 ? 'primary' : 'grey'"
            size="60"
          >
            mdi-cloud-upload
          </v-icon>
          <p>
            {{ t('Title_.subTitle.uploadImage') }}
            <v-btn
              for="fileInput"
              tag="label"
              text
              color="primary"
              class="pa-1 ma-1"
              height="auto"
              id="choose-img"
            >
              {{ t('Action_.buttons.clickToSelect') }}
            </v-btn>
          </p>
          <v-file-input
            :key="uploadedFiles.length"
            v-show="false"
            accept="image/*"
            id="fileInput"
            @change="manualUpload($event)"
          ></v-file-input>
        </v-row>
        <v-virtual-scroll
          v-if="uploadedFiles.length > 0"
          :items="uploadedFiles"
          :height="uploadedFiles.length > 1 ? '150' : '65'"
          item-height="50"
        >
          <template #default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                  <span class="ml-3 text--secondary">
                    {{ item.size }} bytes
                  </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click.stop="removeFile(item.name)" icon>
                  <v-icon> mdi-close-circle </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="closeDialog" icon>
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>

        <v-btn icon @click.stop="submitFiles()">
          <v-icon
            id="upload-button"
            :color="uploadedFiles.length > 0 ? 'primary' : 'grey'"
          >
            mdi-upload
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import languages from '../locales/languages';
import { useImageManipulationStore } from '@/store/pinia/image-manipulation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, defineProps, onBeforeUnmount } from 'vue';

const imageManipulationStore = useImageManipulationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  multiple: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Local variables ---
const dragover = ref(false);
const uploadedFiles = ref([]);

// --- Methods ---
const manualUpload = (event) => {
  if (uploadedFiles.value.length > 0) uploadedFiles.value = [];
  uploadedFiles.value.push(event);
};

const closeDialog = () => {
  uploadedFiles.value = [];
  imageManipulationStore.displayUpload = false;
};

const removeFile = (fileName) => {
  const index = uploadedFiles.value.findIndex((file) => file.name === fileName);
  if (index > -1) {
    uploadedFiles.value.splice(index, 1);
  }
};

const onFileDropped = (event) => {
  let files = event.dataTransfer.files;
  dragover.value = false;

  if (uploadedFiles.value.length > 0) {
    uploadedFiles.value = [];
  }

  // const toastParams = {
  //   type: 'error',
  //   messages: {
  //     error: t('Msg_.onlyOneFileAtTime'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);

  if (!props.multiple && files.length > 1) {
    // this.$root.message.showToastNotification();
  } else {
    for (let i of files.length) {
      uploadedFiles.value = [...uploadedFiles.value, files[i]];
    }
  }
};

const submitFiles = () => {
  // const toastParams = {
  //   type: 'error',
  //   messages: {
  //     error: t('Msg_.noFileToUpload'),
  //   },
  // };
  // this.$root.message.setToastParams(toastParams);

  if (!uploadedFiles.value.length > 0) {
    // this.$root.message.showToastNotification();
  } else {
    imageManipulationStore.storeImage(uploadedFiles.value);
    imageManipulationStore.displayCrop = true;
    closeDialog();
  }
};

// --- Lifecycle hooks ---
onBeforeUnmount(() => {
  closeDialog();
});
</script>

<style scoped>
#choose-img {
  cursor: pointer;
}
</style>
