<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="imageManipulationStore.displayCrop"
    width="650"
  >
    <Cropper
      id="cropper"
      :src="imageManipulationStore.rawImage"
      ref="cropper"
      :stencil-props="{
        aspectRatio: 1 / 1,
      }"
    />
    <div class="d-flex justify-end mr-2 pa-3 white">
      <v-btn @click="closeDialog" class="mr-3">{{
        t('Action_.buttons.cancel')
      }}</v-btn>
      <v-btn @click="cropImage" color="primary">{{
        t('Action_.buttons.crop')
      }}</v-btn>
    </div>
  </v-dialog>
</template>

<script setup>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import languages from '../locales/languages';
import { useImageManipulationStore } from '@/store/pinia/image-manipulation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, onBeforeUnmount } from 'vue';

const cropper = ref();
const imageManipulationStore = useImageManipulationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const closeDialog = () => {
  imageManipulationStore.displayCrop = false;
};

const cropImage = () => {
  const { canvas } = cropper.value.getResult();
  let domString = canvas.toDataURL();
  let blob = dataURItoBlob(domString);
  imageManipulationStore.croppedImage = domString;
  imageManipulationStore.outputImage = blob;
  closeDialog();
};

const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = dataURI.split(',')[1];
  else byteString = unescape(dataURI.split(',')[1]);
  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

// --- Lifecycle hooks ---
onBeforeUnmount(() => {
  closeDialog();
});
</script>

<style scoped>
#cropper {
  width: 650px;
  height: 410px;
}
</style>
