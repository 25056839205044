import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useImageManipulationStore = defineStore(
  'imageManipulation',
  () => {
    // --- State ---
    const displayUpload = ref(false);
    const displayCrop = ref(false);
    const rawImage = ref(null);
    const croppedImage = ref(null);
    const outputImage = ref(null);

    // --- Methods ---
    const storeImage = (event) => {
      let image = URL.createObjectURL(event[0]);
      rawImage.value = image;
    };
    return {
      displayUpload,
      displayCrop,
      rawImage,
      croppedImage,
      outputImage,
      storeImage,
    };
  }
);
